import {
    Box
} from "grommet";
import React from "react";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../components/elements/layout';
import LoadingAnimation from "../components/elements/loading_animation";
import service from '../services/partner_service';
import { navigate } from "gatsby";


class Migration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount = async () => {

        let queryString = {};
        if (typeof window !== `undefined`) {
            queryString = window.location.search;
        }
        const urlParams = new URLSearchParams(queryString);
        let hubrise_id = urlParams.get('hubrise_id');
        if (hubrise_id) {
            await service.ping(hubrise_id);
        } 
        setTimeout(() => {
            if(typeof window === 'undefined') return;
            window.location.href = '/';
        })
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Layout style={{ justifyContent: "center", alignContent: "center" }}>
                    <Box width="full" justify="center" align="center" alignSelf="center"
                        overflow={{ vertical: 'auto' }} style={{ minHeight: 'auto' }}
                    >
                        <LoadingAnimation />

                    </Box>
                    <ToastContainer position="top-right"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Layout>
            </BlockUi>
        )
    }
}

export default Migration;
